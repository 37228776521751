import { defineComponent } from 'vue';
import PageHeading from './components/PageHeading.vue';
import LoginForm from './components/forms/LoginForm.vue';
import './assets/base.css';
export default defineComponent({
    components: {
        PageHeading,
        LoginForm
    },
});
