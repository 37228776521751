import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { id: "app" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_PageHeading = _resolveComponent("PageHeading");
    const _component_LoginForm = _resolveComponent("LoginForm");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_PageHeading, { title: "WJAA Basketball" }),
        _createVNode(_component_LoginForm)
    ]));
}
