import { defineComponent, ref } from 'vue';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
export default defineComponent({
    setup() {
        // Firebase authentication logic
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // location.href = "/dashboard";
                console.log("Dashboard");
            }
            else {
                console.log("Something else");
            }
        });
        const emailAddress = ref(null); // Ref for email input
        const password = ref(null); // Ref for password input
        const tryLogin = () => {
            // Check if refs are not null before accessing their values
            if (emailAddress.value && password.value) {
                const email = emailAddress.value.value; // Access email input value
                const pass = password.value.value; // Access password input value
                console.log("Email:", email);
                console.log("Password:", pass);
                signInWithEmailAndPassword(auth, email, pass)
                    .then((userCredential) => {
                    console.log('User signed in:', userCredential.user);
                })
                    .catch((error) => {
                    console.error('Error during sign-in:', error.message);
                });
            }
            else {
                console.error('Input fields are not accessible.');
            }
        };
        return {
            emailAddress,
            password,
            tryLogin
        };
    }
});
