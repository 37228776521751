import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { id: "login" };
const _hoisted_2 = {
    ref: "emailAddress",
    type: "email",
    placeholder: "basketball@wjaa.org"
};
const _hoisted_3 = {
    ref: "password",
    type: "password",
    placeholder: "••••••••"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("form", _hoisted_1, [
        _createElementVNode("fieldset", null, [
            _cache[1] || (_cache[1] = _createElementVNode("legend", null, "Email Address", -1)),
            _createElementVNode("input", _hoisted_2, null, 512)
        ]),
        _createElementVNode("fieldset", null, [
            _cache[2] || (_cache[2] = _createElementVNode("legend", null, "Password", -1)),
            _createElementVNode("input", _hoisted_3, null, 512)
        ]),
        _createElementVNode("input", {
            type: "button",
            value: "Login",
            onClick: _cache[0] || (_cache[0] =
                //@ts-ignore
                (...args) => (_ctx.tryLogin && _ctx.tryLogin(...args)))
        })
    ]));
}
