import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
    apiKey: "AIzaSyC604N5jUd7oYuOOYfYznoDPZWD8d4CHXs",
    authDomain: "wjaa-scheduler-v2.firebaseapp.com",
    projectId: "wjaa-scheduler-v2",
    storageBucket: "wjaa-scheduler-v2.appspot.com",
    messagingSenderId: "662222016645",
    appId: "1:662222016645:web:ab552e0763e50d626dd9ec",
    measurementId: "G-GP09ZPSZS1"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
